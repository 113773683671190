<template>
  <div>
    <header-three/>
    <main>
      <slot/>
    </main>
   <footer-one :style_3="true"/> 
   <back-to-top/>
   <!-- <modal-product /> -->
  </div>
</template>

